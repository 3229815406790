var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"account-title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("companySettingsTitle")))])]),_c('div',{staticClass:"content-delivery mb-5"},[_c('div',{staticClass:"second-tabs"},[(!_vm.user.username.includes('@'))?_c('h3',[_c('img',{attrs:{"src":require("@/assets/images/company-icon.svg")}}),_vm._v("    "+_vm._s(_vm.companyDetails.name)+" ")]):_vm._e(),_c('div',{staticClass:"grey-info"},[_c('b-container',{staticClass:"border-box",attrs:{"fluid":""}},[_c('b-row',{staticClass:"border-box"},[_c('b-col',{staticClass:"border-box grey-info",attrs:{"offset-xl":"2","offset-md":"2","xl":"4","md":"4"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();{
                    return false;
                  }}}},[_c('p',[_vm._v(" ")]),_c('p',[_c('small',[_vm._v(_vm._s(_vm.$t("placeholderOldPassword")))]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"formedituser",attrs:{"type":"password","required":""},domProps:{"value":(_vm.oldPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.oldPassword=$event.target.value}}})]),_c('p',[_c('small',[_vm._v(_vm._s(_vm.$t("passwordField")))]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"formedituser",attrs:{"type":"password","required":""},domProps:{"value":(_vm.newPassword)},on:{"input":[function($event){if($event.target.composing)return;_vm.newPassword=$event.target.value},_vm.validatePassword]}})]),_c('p',[_c('small',[_vm._v(_vm._s(_vm.$t("confirmPasswordField")))]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"formedituser",style:(_vm.confirmPasswordStyle),attrs:{"type":"password","required":""},domProps:{"value":(_vm.confirmPassword)},on:{"input":[function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value},_vm.validatePassword]}})]),_c('p',[_c('password',{attrs:{"strength-meter-only":true},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('p',[(!_vm.isIE)?_c('img',{attrs:{"src":this.validPassword.passwordLengthValid
                        ? require('../../../assets/images/yes.png')
                        : require('../../../assets/images/no.png')}}):_vm._e(),_vm._v("   "+_vm._s(_vm.$t("passwordCriteria1Text"))+" ")]),_c('p',[(!_vm.isIE)?_c('img',{attrs:{"src":this.validPassword.containsLetter
                        ? require('../../../assets/images/yes.png')
                        : require('../../../assets/images/no.png')}}):_vm._e(),_vm._v("   "+_vm._s(_vm.$t("passwordCriteria2Text"))+" ")]),_c('p',[(!_vm.isIE)?_c('img',{attrs:{"src":this.validPassword.containsNumber
                        ? require('../../../assets/images/yes.png')
                        : require('../../../assets/images/no.png')}}):_vm._e(),_vm._v("   "+_vm._s(_vm.$t("passwordCriteria3Text"))+" ")]),_c('br'),_c('p',[_c('a',{staticClass:"notification-action",on:{"click":function($event){return _vm.submitUserPassword()}}},[_vm._v(_vm._s(_vm.$t("changePasswordLabel")))])])])])],1)],1)],1),_c('br')])]),_c('div',{staticClass:"modal-popup",attrs:{"id":"modal_popup_info"}},[_c('div',{staticClass:"modal-popup-content"},[_c('a',{staticClass:"close-button",on:{"click":function($event){return _vm.showModalPopup('modal_popup_info', false)}}},[_c('img',{attrs:{"src":require("@/assets/images/close-button.svg")}})]),(this.companyDataError)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(this.companyDataError.description)}})]):(_vm.changePasswordSuccess)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("changePasswordSuccess")))])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }