<template>
  <div>
    <div class="account-title">
      <h1>{{ $t("companySettingsTitle") }}</h1>
    </div>
    <div class="content-delivery mb-5">
      <div class="second-tabs">
        <h3 v-if="!user.username.includes('@')">
          <img src="@/assets/images/company-icon.svg" />
          &nbsp;&nbsp;&nbsp;{{ companyDetails.name }}
        </h3>

        <div class="grey-info">
          <b-container fluid class="border-box">
            <b-row class="border-box">
              <b-col
                class="border-box grey-info"
                offset-xl="2"
                offset-md="2"
                xl="4"
                md="4"
              >
                <form
                  @submit.prevent="
                    {
                      return false;
                    }
                  "
                >
                  <p>&nbsp;</p>
                  <p>
                    <small>{{ $t("placeholderOldPassword") }}</small>
                    <br />
                    <input
                      type="password"
                      v-model="oldPassword"
                      required
                      class="formedituser"
                    />
                  </p>
                  <p>
                    <small>{{ $t("passwordField") }}</small>
                    <br />
                    <input
                      type="password"
                      v-model="newPassword"
                      @input="validatePassword"
                      required
                      class="formedituser"
                    />
                  </p>
                  <p>
                    <small>{{ $t("confirmPasswordField") }}</small>
                    <br />
                    <input
                      type="password"
                      required
                      :style="confirmPasswordStyle"
                      @input="validatePassword"
                      v-model="confirmPassword"
                      class="formedituser"
                    />
                  </p>
                  <p>
                    <password
                      v-model="newPassword"
                      :strength-meter-only="true"
                    />
                  </p>
                  <p>
                    <img
                      v-if="!isIE"
                      :src="
                        this.validPassword.passwordLengthValid
                          ? require('../../../assets/images/yes.png')
                          : require('../../../assets/images/no.png')
                      "
                    />
                    &nbsp; {{ $t("passwordCriteria1Text") }}
                  </p>
                  <p>
                    <img
                      v-if="!isIE"
                      :src="
                        this.validPassword.containsLetter
                          ? require('../../../assets/images/yes.png')
                          : require('../../../assets/images/no.png')
                      "
                    />
                    &nbsp; {{ $t("passwordCriteria2Text") }}
                  </p>
                  <p>
                    <img
                      v-if="!isIE"
                      :src="
                        this.validPassword.containsNumber
                          ? require('../../../assets/images/yes.png')
                          : require('../../../assets/images/no.png')
                      "
                    />
                    &nbsp; {{ $t("passwordCriteria3Text") }}
                  </p>
                  <br />
                  <p>
                    <a
                      @click="submitUserPassword()"
                      class="notification-action"
                      >{{ $t("changePasswordLabel") }}</a
                    >
                  </p>
                </form>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <br />
      </div>
    </div>

    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.companyDataError">
          <p v-html="this.companyDataError.description"></p>
        </div>
        <div v-else-if="changePasswordSuccess">
          <p>{{ $t("changePasswordSuccess") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import validator from "@/plugins/fieldValidation.js";
import Cookies from "js-cookie";
import Password from "vue-password-strength-meter";

import {
  BContainer,
  BRow,
  BCol

  // VBTooltip,
} from "bootstrap-vue";

export default {
  components: {
    Password,
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol
  },
  props: {
    companyDetails: Object,
    user: Object,
    userRole: String,
    msg: String
  },
  data() {
    return {
      isIE: null,
      companyDataError: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: "",
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false
      },
      changePasswordSuccess: false
    };
  },
  methods: {
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.newPassword,
        this.confirmPassword
      );
    },
    submitUserPassword() {
      this.changePasswordSuccess = false;
      this.deleteCompanySuccess = false;
      this.companyDataError = null;
      this.showModalPopup("modal_popup_info", true);
      if (!this.validPassword.passwordValid) {
        this.companyDataError = {
          description: "Invalid password",
          code: ""
        };
        return;
      } else {
        httpServiceUser.userService
          .confirmUserData(
            this.url,
            {
              id: this.companyDetails.company_id,
              current_password: this.oldPassword,
              new_password: this.newPassword
            },
            this.token
          )
          .then(function(response) {
            return response;
          })
          .catch(error => {
            this.companyDataError = error.response.code;
          })
          .then(response => {
            if (response.status == 200) {
              this.oldPassword = "";
              this.newPassword = "";
              this.confirmPassword = "";
              this.changePasswordSuccess = true;
            } else if (response.status == 400) {
              this.companyDataError = response.data;
            } else {
              this.companyDataError = { description: response.code };
            }
          });
      }
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    }
  },
  mounted() {
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
  },
  computed: {
    confirmPasswordStyle() {
      return this.confirmPassword === ""
        ? ""
        : this.newPassword === this.confirmPassword
        ? ""
        : "outline-color: #ff0000a6;";
    }
  },
  created() {
    this.url = Cookies.get("userLink");
    this.token = Cookies.get("token");
  }
};
</script>
<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<style scoped src="bootstrap-vue/dist/bootstrap-vue.css"></style>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.content {
  width: 95%;
  margin: 20px auto 20px auto;
}
</style>
